@import "../../../assets/css/base/media-queries";
.title {
  position: relative;
  display: flex;
  align-items: baseline;
  margin: 10px 0px 40px;
  width: 100%;
  font-size: 32px;
  white-space: nowrap;
  gap: 10px;
  @apply text-textSecondary;
  @include breakpoint-down(md) {
    font-size: 26px;
  }
  span {
    @apply text-secondary;
    font-weight: 400;
    font-size: 20px;
    @include breakpoint-down(md) {
      font-size: 16px;
    }
  }
  &:after {
    content: "";
    display: block;
    position: relative;
    top: -5px;
    width: 300px;
    height: 1px;
    margin-left: 20px;
    @apply bg-navyLight;
  }
}
