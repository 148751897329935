@import '../../../assets/css/base/media-queries';
header {
  top: -100px;
  transition: top 0.3s;
  &.isSticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    .nav {
      @apply bg-primary;
      height: 64px;
      box-shadow: 0 10px 30px -10px rgba(2, 12, 27, .7);
    }
  }
}

.nav {
  width: 100%;
  //transition: height 0.5s ease-in;
  height: 88px;
  display: flex;
  @include breakpoint-down(md) {
    padding: 15px 0;
    height: 64px;
  }

  &Default {
    //padding: 0;
  }

  .navbarBrand {
    position: relative;
    z-index: 999;

    &Logo {
      width: 50px;
      @include breakpoint-down(lg) {
        width: 50px;
      }
      @media (max-width: 343px) {
        width: 50px;
      }
    }
  }

  .navbarToggle {
    position: relative;
    z-index: 999;
  }

  ul {
    li {
      font-weight: 400;
      font-size: 13px;
      line-height: 27px;
      text-align: right;
      counter-increment: item 1;
      @apply text-textSecondary;
      a {
        @apply text-textSecondary;
        padding: 6px 16px;
        line-height: inherit;
        border-radius: 22px;
        transition: color 0.5s;
        &:before {
          content: "0" counter(item) ".";
          margin-right: 5px;
          @apply text-secondary;
          text-align: right;
        }
        @include breakpoint-down(lg) {
          padding: 6px 9px;
        }

        &:hover, &.active {
          @apply text-secondary;
        }
      }
    }
  }

  @include breakpoint-down(md) {
    .navCollapse {
      @apply bg-primaryLight;
      box-shadow: 0 10px 30px -10px rgba(2, 12, 27, .7);
      position: absolute;
      top: 64px;
      left: 0;
      right: 0;
      z-index: 998;
      transition: visibility 0.5s;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      visibility: hidden;

      ul {
        z-index: 1;
        position: relative;

        li {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          text-align: center;
          padding: 12px 16px;
          border-bottom: 1px solid theme('colors.primary');

          &:last-child {
            border-bottom: unset;
          }

          opacity: 0;
          transform: translate3d(-40px, 0, 0);
          -webkit-transform: translate3d(-40px, 0, 0);
          transition: transform .3s, opacity 0.3s;
          -webkit-transition: -webkit-transform 0.3s ease 0s, opacity 0.2s ease 0s;
          will-change: transform, opacity;
          //animation-timing-function: cubic-bezier(.45, .005, 0, 1);
          @for $i from 0 to 15 {
            &.delay#{$i + 1} {
              transition-delay: #{$i * 0.1}s;
              -webkit-transition-delay: #{$i * 0.1}s;
            }
          }
        }
      }

      &.active {
        visibility: visible;
        ul {
          li {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }
}
