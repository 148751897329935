@import '../../../assets/css/base/media-queries';
.side {
  width: 40px;
  position: fixed;
  bottom: 0;
  left: auto;
  right: 40px;
  z-index: 10;
  color: #a8b2d1;

  @media (max-width: 1080px) {
    left: auto;
    right: 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }
  .email {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 90px;
      margin: 0 auto;
      background-color: #a8b2d1;
    }

    a {
      margin: 20px auto;
      padding: 10px;
      letter-spacing: 0.1em;
      writing-mode: vertical-rl;
      display: inline-block;
      text-decoration: none;
      text-decoration-skip-ink: auto;
      color: inherit;
      position: relative;
      transition: theme('transitions.default');

      &:hover,
      &:focus {
        transform: translateY(-3px);
        @apply text-secondary;
      }
    }
  }
}

