@import '../../../assets/css/base/media-queries';
.loader {
  @apply bg-primary;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .logoWrapper {
    svg {
      width: 120px;
      height: 120px;
      @apply text-secondary;
      fill: none;
      user-select: none;
      #B {
        opacity: 0;
      }
    }
  }
}
.mounted {

}
