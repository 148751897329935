@import '../../../assets/css/base/media-queries';
@import '../../../assets/css/base/mixin';

.about {
  padding: 50px 0;
  @include breakpoint-down(md) {
    padding: 32px 0;
  }

  :global(.text) {
    max-width: 80%;
    @include breakpoint-down(md) {
      max-width: 100%;
    }
  }
}
