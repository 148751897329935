@import '../../../assets/css/base/media-queries';
.side {
  width: 40px;
  position: fixed;
  bottom: 0;
  left: 40px;
  right: auto;
  z-index: 10;
  color: #a8b2d1;

  @media (max-width: 1080px) {
    left: 20px;
    right: auto;
  }

  @media (max-width: 768px) {
    display: none;
  }
  .socialList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 90px;
      margin: 0 auto;
      background-color: #a8b2d1;
    }

    li {
      &:last-of-type {
        margin-bottom: 20px;
      }

      a {
        padding: 10px;
        display: inline-block;
        text-decoration: none;
        text-decoration-skip-ink: auto;
        color: inherit;
        position: relative;
        transition: theme('transitions.default');

        &:hover,
        &:focus {
          transform: translateY(-3px);
          @apply text-secondary;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

