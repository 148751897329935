@import '../../../assets/css/base/media-queries';
@import '../../../assets/css/base/mixin';

.jobs {
  padding: 50px 0;
  @include breakpoint-down(md) {
    padding: 32px 0;
  }

  .tabsWrapper {
    display: flex;
    min-height: 340px;
    @include breakpoint-down(md) {
      display: block;
      min-height: unset;
    }

    .tabsNav {
      position: relative;
      z-index: 3;
      width: max-content;
      padding: 0;
      margin: 0;
      list-style: none;
      @include breakpoint-down(md) {
        display: flex;
        overflow-x: auto;
        margin-bottom: 30px;
        width: calc(100% + 50px);
        padding-left: 25px;
        margin-left: -25px;
        border-bottom: 2px solid theme('colors.navyLight');
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .activeLine {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 2px;
        height: 42px;
        border-radius: 4px;
        @apply bg-secondary;
        transform: translateY(calc(0 * 42px));
        transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
        @include breakpoint-down(md) {
          top: auto;
          bottom: 0;
          width: 100%;
          max-width: 120px;
          height: 2px;
          margin-left: 25px;
          transform: translateX(calc(0 * 120px));
        }
      }

      button {
        text-decoration: none;
        text-decoration-skip-ink: auto;
        position: relative;
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
        display: flex;
        align-items: center;
        width: 100%;
        height: 42px;
        padding: 0 20px 2px;
        border-left: 2px solid theme('colors.navyLight');
        background-color: transparent;
        font-size: 13px;
        text-align: left;
        white-space: nowrap;
        @apply text-textPrimary;
        @include breakpoint-down(md) {
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          width: 120px;
          min-width: 120px;
          padding: 0 15px;
          border-left: 0;
          //border-bottom: 2px solid theme('colors.navyLight');
          text-align: center;
        }
        &:hover {
          @apply bg-navyLight;
          @apply text-secondary;
        }

        &.active {
          @apply text-secondary;
        }
      }
    }

    .tabsPanel {
      position: relative;
      width: 100%;
      margin-left: 20px;
      @include breakpoint-down(md) {
        margin-left: 0;
      }

      > div {
        width: 100%;
        height: auto;
        padding: 10px 5px;
      }

      h3 {
        margin-bottom: 2px;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.3;
        @include breakpoint-down(md) {
          font-size: 20px;
        }

        span {

        }

        .company {
          @apply text-secondary;
        }
      }

      .range {
        margin-bottom: 25px;
        font-size: 13px;
        @apply text-textPrimary;
      }

      :global(.text) {
        ul {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}
