html, body {
  @apply bg-primary text-textPrimary;
  //overflow: hidden;
  //scroll-behavior: smooth;
  //height: fit-content;
  //width: 100vw;
  //overflow-x: hidden;
  //overflow-y: scroll;
  //min-height: 100vh;
}
*:not(html):not(body) {

}
