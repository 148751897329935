@import '../../../assets/css/base/media-queries';
@import '../../../assets/css/base/mixin';

.contact {
  padding: 50px 0;
  text-align: center;
  &Title {
    @apply text-secondary;
    display: block;
    margin-bottom: 20px;
    font-weight: 400;
  }
  &BigTitle {
    @apply text-textSecondary;
    margin: 0 0 10px;
    font-weight: 600;
    line-height: 1.1;
    font-size: 45px;
  }
  &Description {
    margin-bottom: 50px;
  }
}
