@import '../../../assets/css/base/media-queries';
@import '../../../assets/css/base/mixin';

.banner {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  min-height: calc(100vh - 88px);
  height: calc(100vh - 88px);
  @include breakpoint-down(md) {
    min-height: calc(100vh - 64px);
    height: calc(100vh - 64px);
  }

  .smallTitle {
    font-size: 16px;
    line-height: 1.2;
    @apply text-secondary;
    margin-bottom: 10px;
  }

  .titleFirst {
    font-size: 50px;
    line-height: 1;
    @apply text-textSecondary;
    margin-bottom: 10px;
    font-weight: 700;
    letter-spacing: -.5px;
    @include breakpoint-down(md) {
      font-size: 30px;
    }
  }

  .titleSecond {
    font-size: 30px;
    line-height: 1;
    @apply text-textPrimary;
    margin-bottom: 30px;
    font-weight: 700;
    letter-spacing: -.5px;
    @include breakpoint-down(md) {
      font-size: 20px;
    }
  }

  .description {
    font-size: 20px;
    line-height: 1.2;
    @apply text-textPrimary;
    margin-bottom: 50px;
    max-width: 70%;
    @include breakpoint-down(md) {
      max-width: 100%;
      font-size: 14px;
    }
  }
}
