@import '../../../assets/css/base/media-queries';
footer {
  padding: 50px 0;
  position: relative;
  @include breakpoint-down(lg) {
    padding: 32px 0;
    overflow: hidden;
  }
  .copy {
    text-align: center;
    font-size: 12px;
    line-height: 1.3;
  }
  .social {
    display: none;
    @include breakpoint-down(md) {
      width: 100%;
      max-width: 270px;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      padding: 0;
      margin: 0 auto;
      list-style: none;
      a {
        padding: 10px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

